import {
  Component,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { AdAreaComponent } from './ad-area.component';
import { SiteService } from '../../ecommerce/services/site/site.service';

@Component({
  selector: 'ad-area-mobile',
  templateUrl: './ad-area-mobile.component.html',
  styleUrls: ['./ad-area-mobile.component.scss'],
})
export class AdAreaMobileComponent
  extends AdAreaComponent
  implements AfterViewInit
{
  readonly DEFAULT_NB_SHOWED_BOXES: number;

  @Input() view: string;
  @Input() width: number;
  @Input() nbShowedBoxes: number;

  imageStyle: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private siteService: SiteService
  ) {
    super();

    this.DEFAULT_NB_SHOWED_BOXES = 1;
  }

  ngOnInit() {
    if (!this.view) {
      this.view = 'slider';
    }

    if (this.siteService.IS_BROWSER) {
      this.setConfiguration();
    }
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  setConfiguration() {
    if (this.width === undefined) {
      this.width = window.innerWidth;
    }

    if (this.nbShowedBoxes === undefined) {
      if (window.innerWidth <= 500) {
        this.nbShowedBoxes = 1;
      } else {
        this.nbShowedBoxes = this.DEFAULT_NB_SHOWED_BOXES;
      }
    }

    const width = `${100 / this.nbShowedBoxes}%`;

    this.imageStyle = {
      flex: `0 0 ${width}`,
      'max-width': width,
    };
  }
}
